import { render, staticRenderFns } from "./UserVenueDeals.vue?vue&type=template&id=28e4a999&scoped=true&"
import script from "./UserVenueDeals.vue?vue&type=script&lang=js&"
export * from "./UserVenueDeals.vue?vue&type=script&lang=js&"
import style0 from "./UserVenueDeals.vue?vue&type=style&index=0&id=28e4a999&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e4a999",
  null
  
)

export default component.exports