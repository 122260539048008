<template>
  <div
    v-if="!$apollo.loading"
    class="bg-page-dark user-venue-deal-page h-100 d-flex flex-column"
  >
    <b-container class="bg-white shadow-lg border p-0 flex-fill">
      <div class="p-2">
        <router-link
          :to="{ name: 'account' }"
          class="btn btn-outline-primary btn-sm"
        >
          <font-awesome-icon icon="arrow-left" fixed-width />
        </router-link>
      </div>
      <b-row>
        <b-col cols="6" md="3" offset-md="3" class="text-right mb-3">
          <b-link
            :to="{ name: 'credits-captured' }"
            class="text-black text-center"
          >
            <span class="h3 d-block mb-0 text-center">
              {{ statistics.creditsCapturedAmount }}</span
            >
            <span class="d-block text-uppercase">Remboursé</span>
          </b-link>
        </b-col>
        <b-col cols="6" md="3" class="text-left">
          <b-link
            :to="{ name: 'credits-authorized' }"
            class="text-dark text-center"
          >
            <span class="h3 mb-0 d-block text-center">{{
              statistics.creditsAuthorizedAmount
            }}</span>
            <span class="d-block text-uppercase">En attente</span>
          </b-link>
        </b-col>
      </b-row>
      <b-list-group v-if="userVenueDeals && userVenueDeals.length">
        <b-list-group-item
          v-for="userVenueDeal in userVenueDeals"
          :key="userVenueDeal.id"
          class="border-bottom m-0 rounded-0 row no-gutters d-flex p-2"
        >
          <div class="d-flex w-100">
            <h3 class="font-weight-demi h2">
              <b-badge
                variant="primary rounded h-100 d-flex align-items-center"
              >
                {{ userVenueDeal.venueDeal.price }}€
              </b-badge>
            </h3>
            <div>
              <div class="p-2">
                <h6 class="text-uppercase font-weight-demi mb-0">
                  <router-link
                    v-if="userVenueDeal.venueDeal.venue"
                    :to="{
                      name: 'user-venue-deals-venue',
                      params: { id: userVenueDeal.venueDeal.venue.id }
                    }"
                  >
                    {{ userVenueDeal.venueDeal.venue.name }}
                  </router-link>
                  <span v-else>Venue supprimée</span>
                </h6>

                <small>
                  <font-awesome-icon :icon="['far', 'clock']" fixed-width />
                  {{ userVenueDeal.createdAt.diffForHumans }}
                </small>
              </div>
            </div>
            <div class="ml-auto">
              <b-modal
                :id="'cancelDealModal' + userVenueDeal.id"
                centered
                @ok="cancel(userVenueDeal.id)"
              >
                Annuler ce Bon Plan
              </b-modal>
              <b-btn
                v-if="userVenueDeal.status === 'pending'"
                v-b-modal="'cancelDealModal' + userVenueDeal.id"
                variant="danger"
              >
                <font-awesome-icon icon="trash" />
              </b-btn>
            </div>
          </div>
          <div v-if="userVenueDeal.needNote" class="col-12 mt-2">
            <div v-if="uploading"><loader size="1x" />Envoie en cours</div>
            <b-form-file
              v-show="!uploading"
              v-model="file"
              :state="Boolean(file)"
              placeholder="Envoyer ma note"
              @input="input(userVenueDeal, ...arguments)"
            />
          </div>
          <b-alert
            v-else-if="userVenueDeal.status === 'sending'"
            show
            variant="warning"
            class="d-flex align-items-center w-100"
          >
            <font-awesome-icon :icon="['far', 'paper-plane']" size="2x" />
            <p class="mb-0 mx-3">
              Votre note a bien été envoyée, elle est en attente de validation
            </p>
          </b-alert>
          <b-alert
            v-else-if="userVenueDeal.status === 'captured'"
            show
            variant="secondary"
            class="d-flex align-items-center w-100"
          >
            <p class="mb-0">Bon plan remboursé</p>
          </b-alert>
          <b-alert
            v-else-if="userVenueDeal.status === 'authorised'"
            show
            variant="success"
            class="d-flex align-items-center w-100"
          >
            <p class="mb-0">
              Félicitations! Votre note a été validée! Votre remboursement sera
              effectué le mois prochain.
            </p>
          </b-alert>
          <b-alert
            v-else-if="userVenueDeal.status === 'refused'"
            show
            variant="danger"
            class="d-flex align-items-center w-100"
          >
            <p class="mb-0">
              Oops! Votre note a été refusé... Souhaitez-vous la photographier
              de nouveau ?
            </p>
          </b-alert>
          <b-alert
            v-else-if="userVenueDeal.status === 'expired'"
            show
            variant="danger"
            class="d-flex align-items-center w-100"
          >
            <p class="mb-0">
              Nous sommes désolés, le délai de 48h pour nous envoyer la note a
              expiré
            </p>
          </b-alert>
        </b-list-group-item>
      </b-list-group>
      <div v-else class="p-2">
        <b-col cols="12" md="8" xl="6" offset-md="2" offset-xl="3">
          <div class="my-3">
            <font-awesome-layers class="fa-4x">
              <font-awesome-icon
                icon="circle"
                class="text-primary text-center"
              />
              <font-awesome-icon
                icon="thumbs-up"
                class="text-white"
                transform="shrink-8"
                style="width: 64px"
              />
            </font-awesome-layers>
          </div>
          <h2 class="title">Vos Bons Plans</h2>
          <p class="mb-5">
            Vous n'avez pas encore utilisé de Bons Plans. N'hésitez plus,
            trouvez le restaurant qui vous plait, débloquez son Bon Plan et
            lancez-vous
          </p>
        </b-col>
      </div>

      <portal to="content">
        <transition name="swipe">
          <router-view />
        </transition>
      </portal>
    </b-container>
  </div>
  <div v-else class="h-100 d-flex align-items-center justify-content-center">
    <font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
  </div>
</template>
<script>
import CANCEL_DEAL from "@/graphql/mutations/CancelDeal.gql"
import USER_VENUE_DEALS from "@/graphql/auth/userVenueDeals.gql"
import STORE_RECEIPT from "@/graphql/mutations/StoreReceipt.gql"
import Loader from "@/components/Loader"
import _ from "lodash"
import STATISTICS from "@/graphql/auth/statistics.gql"
export default {
  components: {
    Loader
  },
  data() {
    return {
      file: null,
      uploading: false
    }
  },
  apollo: {
    userVenueDeals: {
      query: USER_VENUE_DEALS,
      client: "auth",
      fetchPolicy: "no-cache"
    },
    statistics: {
      query: STATISTICS,
      skip: false,
      client: "auth"
    }
  },
  methods: {
    input(userVenueDeal) {
      this.uploading = true
      const file = this.file
      this.$apollo.mutate({
        mutation: STORE_RECEIPT,
        client: "auth",
        variables: {
          file,
          venueId: userVenueDeal.venueDeal.venue.id,
          userVenueDealId: userVenueDeal.id
        },
        update: (store, { data: { storeReceipt } }) => {
          this.uploading = false
          userVenueDeal.needNote = false
          userVenueDeal.historicals.push({
            newValue: "sending",
            createdAt: storeReceipt.createdAt
          })
        }
      })
    },
    cancel(userVenueDealId) {
      this.$apollo.mutate({
        mutation: CANCEL_DEAL,
        client: "auth",
        variables: {
          userVenueDealId: userVenueDealId
        },
        update: (store, { data: { cancelDeal } }) => {
          let index = this.userVenueDeals.findIndex(
            uv => uv.id === userVenueDealId
          )
          this.userVenueDeals.splice(index, 1)
          this.$emit("canceldeal")
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.list-group-item {
  border: 0;
}
.router-view {
  top: 0;
  left: 0;
  z-index: 15;
}
h1 {
  font-size: 3rem;
}
</style>
